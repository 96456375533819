<template>
  <b-card>
    <div class="custom-container">
      <!-- banner bienvenida -->
      <div
        class="row d-flex py-5 mb-5 rounded banner-content position-relative"
      >
        <!-- arrow down scroll -->
        <div>
          <i
            class="fas fa-arrow-down fa-bounce position-absolute"
            style="bottom: -30px; left: 50%; font-size: 2rem"
          ></i>
        </div>
        <!-- icons float -->
        <img
          class="position-absolute d-none d-lg-block"
          style="left: 50%; transform: rotate(30deg)"
          src="/img/undraw_alarm-clock.svg"
          alt=""
        />
        <img
          class="position-absolute d-none d-lg-block"
          style="right: 10%; opacity: 90%; transform: rotate(-10deg)"
          src="/img/undraw_pointer.svg"
          alt=""
        />
        <img
          class="position-absolute d-none d-lg-block"
          style="right: 35%; top: 40%; opacity: 50%"
          src="/img/undraw_code.svg"
          alt=""
        />
        <img
          class="position-absolute"
          style="left: 10%; bottom: 30%; opacity: 50%; transform: rotate(30deg)"
          src="/img/undraw_star.svg"
          alt=""
        />
        <img
          class="position-absolute d-none d-lg-block"
          style="width: 100px; bottom: 10%; left: 30%; transform: scaleX(-1)"
          src="/img/undraw_fun-arrow.svg"
          alt=""
        />
        <div class="col-12 col-lg">
          <div>
            <h1 class="display-4" style="font-weight: 700; margin-bottom: 1rem">
              ¡Empecemos a aprender,
              <span class="text-primary text-capitalize">{{
                `${persona.nombres} ${persona.apellidos}`
              }}</span
              >!
            </h1>
            <p class="h3">
              Destaca entre la multitud con las habilidades más actuales y únete
              a nuestra comunidad de aprendices apasionados. Juntos, podemos
              llegar lejos y lograr grandes cosas.
            </p>
          </div>
        </div>
        <div class="col mt-5 mt-lg-0">
          <img
            src="/img/undraw_online_learning_re_qw08.svg"
            alt="learning_img"
          />
        </div>
      </div>
      <!-- curso recomendado -->
      <div v-if="cursoRandom !== null">
        <div class="mb-3">
          <h1 class="mb-3">Qué aprender ahora</h1>
          <h3 class="myBorder">Nuestras principales sugerencias para ti</h3>
        </div>
        <b-card
          bg-variant="dark"
          class="border p-2 pointer custom-card"
          @click="
            $router.push(`/helex/Ayuda/Academia/InfoCurso/${cursoRandom?.id}`)
          "
        >
          <div class="d-flex row">
            <div class="col-12 col-lg-3 d-flex align-items-center">
              <img
                class="img-fluid w-100 rounded"
                :src="
                  cursoRandom.previewImagenUrl ?? '/img/image_cursos_alt.png'
                "
                :alt="cursoRandom.titulo"
              />
            </div>
            <div class="col-12 col-lg mt-3 mt-lg-0">
              <h2 class="font-weight-bold text-capitalize">
                {{ cursoRandom.titulo }}
              </h2>
              <p
                class="h4 text-white-50 custom-text-truncate-p"
                style="line-height: 1.5"
              >
                {{ cursoRandom.descripcion }}
              </p>

              <div class="d-flex flex-column align-items-start flex-wrap">
                <div class="d-flex align-items-center">
                  <span
                    class="custom-punto d-flex align-items-center font-weight-bold text-success my-2"
                  >
                    {{ cursoRandom.categoriaCurso?.descripcion }}
                  </span>
                  <span
                    class="custom-punto d-flex align-items-center text-capitalize"
                    >Por {{ cursoRandom.responsable.nombres }}
                    {{ cursoRandom.responsable.apellidos }}</span
                  >
                  <vs-tooltip>
                    <i
                      :class="`fas fa-${
                        cursoRandom.privacidad === 1 ? 'earth-americas' : 'lock'
                      }`"
                    ></i>
                    <template #tooltip>{{
                      cursoRandom.privacidad === 1
                        ? "Curso público"
                        : "Curso privado"
                    }}</template>
                  </vs-tooltip>
                </div>
                <div class="d-flex flex-wrap align-items-center">
                  <span class="custom-punto d-flex align-items-center">
                    <i class="fas fa-circle-check mr-1"></i> Última
                    actualización:
                    <span class="text-primary ml-1">
                      {{
                        cursoRandom.ultimaActualizacion | formatLastUpdateDate
                      }}</span
                    >
                  </span>
                  <i class="fas fa-clock mr-1"></i>
                  <span
                    class="text-white-50 custom-punto d-flex align-items-center"
                    >{{
                      cursoRandom.clases.reduce(
                        (totalHoras, clase) =>
                          totalHoras + parseInt(clase.duracion),
                        0
                      )
                    }}h</span
                  >
                  <i class="fas fa-play-circle mr-1"></i>
                  <span class="text-white-50"
                    >{{ cursoRandom.clases.length }}
                    {{
                      cursoRandom.clases.length > 1 ? "clases" : "clase"
                    }}</span
                  >
                </div>
                <!-- <div
                  class="d-flex align-items-center flex-wrap"
                  style="gap: 10px"
                >
                  <div class="d-flex align-items-center flex-wrap">
                    <b-form-rating
                      id="rating-inline"
                      variant="warning"
                      readonly
                      inline
                      show-value
                      no-border
                      value="4.9"
                      class="bg-transparent p-0"
                      size="lg"
                      style="font-size: 1.5rem"
                    ></b-form-rating>
                    <span style="font-size: 1.5rem">(120)</span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <!-- cursos nuevos -->
      <div v-if="cursosRecientes.length > 0" class="my-5">
        <h3 class="myBorder">
          Cursos agregados recientemente
          <b-badge variant="info">Nuevos</b-badge>
        </h3>
        <vs-card-group>
          <vs-card
            class="custom-card-academia"
            v-for="curso in cursosRecientes"
            :key="curso.id"
            :id="`popover-${curso.id}`"
          >
            <template #title>
              <div class="custom-text-truncate-title">
                <h3 class="text-capitalize">
                  {{ curso.titulo }}
                </h3>
              </div>
            </template>
            <template #img>
              <img
                style="min-height: 162.89px; max-height: 162.89px; border-radius: 10px;"
                :src="curso.previewImagenUrl ?? `/img/image_cursos_alt.png`"
                :alt="curso.titulo"
              />
            </template>
            <template #text>
              <div class="d-flex align-items-center text-white-50">
                <small
                  class="custom-punto d-flex align-items-center text-capitalize"
                  >Por {{ curso.responsable.nombres }}
                  {{ curso.responsable.apellidos }}</small
                >
                <vs-tooltip>
                  <i
                    :class="`fas fa-${
                      curso.privacidad === 1 ? 'earth-americas' : 'lock'
                    }`"
                  ></i>
                  <template #tooltip>{{
                    curso.privacidad === 1 ? "Curso público" : "Curso privado"
                  }}</template>
                </vs-tooltip>
              </div>
              <small class="d-flex align-items-center text-white-50 mt-2">
                <i class="fas fa-circle-check mr-1"></i> Última actualización:
                <span class="text-primary ml-1">
                  {{ curso.ultimaActualizacion | formatLastUpdateDate }}</span
                >
              </small>
              <!-- <div class="d-flex align-items-center">
                <b-form-rating
                  id="rating-inline"
                  variant="warning"
                  readonly
                  inline
                  show-value
                  no-border
                  value="3.8"
                ></b-form-rating>
                <small class="text-muted">(30)</small>
              </div> -->
            </template>
            <template #interactions>
              <vs-button   icon>
                <i class="far fa-clock mr-2"></i>
                <span class="span text-white"
                  >{{
                    curso.clases.reduce(
                      (totalHoras, clase) =>
                        totalHoras + parseInt(clase.duracion),
                      0
                    )
                  }}h</span
                >
              </vs-button>
              <vs-button class="btn-chat" dark icon>
                <i class="far fa-circle-play mr-2"></i>
                <span class="span">{{ curso.clases.length }}</span>
              </vs-button>
            </template>
          </vs-card>
        </vs-card-group>
        <b-popover
          v-for="curso in cursosRecientes"
          :target="`popover-${curso.id}`"
          triggers="hover focus"
          :key="curso.id"
        >
          <template #title>
            <h3 class="custom-text-truncate-title text-capitalize">
              {{ curso.titulo }}
            </h3>
          </template>
          <div class="d-flex justify-content-between my-2">
            <div class="d-flex align-items-center">
              <i
                class="fas fa-clock mr-1 text-warning"
                style="font-size: 14px"
              ></i>
              <b style="font-size: 14px"
                >{{
                  curso.clases.reduce(
                    (totalHoras, clase) =>
                      totalHoras + parseInt(clase.duracion),
                    0
                  )
                }}h</b
              >
            </div>

            <div>
              <i class="fas fa-play-circle mr-1" style="font-size: 14px"></i>
              <b class="text-primary" style="font-size: 14px"
                >{{ curso.clases.length
                }}{{ curso.clases.length > 1 ? " clases" : " clase" }}
              </b>
            </div>
          </div>
          <p
            class="custom-text-truncate-p text-white-50"
            style="font-size: 13px"
          >
            {{ curso.descripcion }}
          </p>
          <vs-button
             
            block
            :to="`/helex/Ayuda/Academia/InfoCurso/${curso.id}`"
          >
            <i class="fas fa-video mr-2"></i> Ir a curso
          </vs-button>
        </b-popover>
      </div>

      <!-- curso publicos -->
      <div v-if="cursosPublicos.length > 0" class="my-5">
        <h3 class="myBorder">Cursos públicos</h3>
        <vs-card-group>
          <vs-card
            class="custom-card-academia"
            v-for="curso in cursosPublicos"
            :key="curso.id"
            :id="`popover-${curso.id}-public`"
          >
            <template #title>
              <div class="custom-text-truncate-title">
                <h3 class="text-capitalize">
                  {{ curso.titulo }}
                </h3>
              </div>
            </template>
            <template #img>
              <img
                style="min-height: 162.89px; max-height: 162.89px; border-radius: 10px;"
                :src="curso.previewImagenUrl ?? `/img/image_cursos_alt.png`"
                :alt="curso.titulo"
              />
            </template>
            <template #text>
              <div class="d-flex align-items-center text-white-50">
                <small
                  class="custom-punto d-flex align-items-center text-capitalize"
                  >Por {{ curso.responsable.nombres }}
                  {{ curso.responsable.apellidos }}</small
                >
                <vs-tooltip>
                  <i
                    :class="`fas fa-${
                      curso.privacidad === 1 ? 'earth-americas' : 'lock'
                    }`"
                  ></i>
                  <template #tooltip>{{
                    curso.privacidad === 1 ? "Curso público" : "Curso privado"
                  }}</template>
                </vs-tooltip>
              </div>
              <small class="d-flex align-items-center text-white-50 mt-2">
                <i class="fas fa-circle-check mr-1"></i> Última actualización:
                <span class="text-primary ml-1">
                  {{ curso.ultimaActualizacion | formatLastUpdateDate }}</span
                >
              </small>
              <!-- <div class="d-flex align-items-center">
                <b-form-rating
                  id="rating-inline"
                  variant="warning"
                  readonly
                  inline
                  show-value
                  no-border
                  value="3.8"
                ></b-form-rating>
                <small class="text-muted">(30)</small>
              </div> -->
            </template>
            <template #interactions>
              <vs-button   icon>
                <i class="far fa-clock mr-2"></i>
                <span class="span text-white"
                  >{{
                    curso.clases.reduce(
                      (totalHoras, clase) =>
                        totalHoras + parseInt(clase.duracion),
                      0
                    )
                  }}h</span
                >
              </vs-button>
              <vs-button class="btn-chat" dark icon>
                <i class="far fa-circle-play mr-2"></i>
                <span class="span">{{ curso.clases.length }}</span>
              </vs-button>
            </template>
          </vs-card>
        </vs-card-group>
        <b-popover
          v-for="curso in cursosPublicos"
          :target="`popover-${curso.id}-public`"
          triggers="hover focus"
          :key="curso.id"
        >
          <template #title>
            <h3 class="custom-text-truncate-title text-capitalize">
              {{ curso.titulo }}
            </h3>
          </template>
          <div class="d-flex justify-content-between my-2">
            <div class="d-flex align-items-center">
              <i
                class="fas fa-clock mr-1 text-warning"
                style="font-size: 14px"
              ></i>
              <b style="font-size: 14px"
                >{{
                  curso.clases.reduce(
                    (totalHoras, clase) =>
                      totalHoras + parseInt(clase.duracion),
                    0
                  )
                }}h</b
              >
            </div>

            <div>
              <i class="fas fa-play-circle mr-1" style="font-size: 14px"></i>
              <b class="text-primary" style="font-size: 14px"
                >{{ curso.clases.length
                }}{{ curso.clases.length > 1 ? " clases" : " clase" }}
              </b>
            </div>
          </div>
          <p
            class="custom-text-truncate-p text-white-50"
            style="font-size: 13px"
          >
            {{ curso.descripcion }}
          </p>
          <vs-button
             
            block
            :to="`/helex/Ayuda/Academia/InfoCurso/${curso.id}`"
          >
            <i class="fas fa-video mr-2"></i> Ir a curso
          </vs-button>
        </b-popover>
      </div>

      <!-- curso privados -->
      <div v-if="cursosPrivados.length > 0" class="my-5">
        <h3 class="myBorder">Cursos privados</h3>
        <vs-card-group>
          <vs-card
            class="custom-card-academia"
            v-for="curso in cursosPrivados"
            :key="curso.id"
            :id="`popover-${curso.id}-private`"
          >
            <template #title>
              <div class="custom-text-truncate-title">
                <h3 class="text-capitalize">
                  {{ curso.titulo }}
                </h3>
              </div>
            </template>
            <template #img>
              <img
                style="min-height: 162.89px; max-height: 162.89px; border-radius: 10px;"
                :src="curso.previewImagenUrl ?? `/img/image_cursos_alt.png`"
                :alt="curso.titulo"
              />
            </template>
            <template #text>
              <div class="d-flex align-items-center text-white-50">
                <small
                  class="custom-punto d-flex align-items-center text-capitalize"
                  >Por {{ curso.responsable.nombres }}
                  {{ curso.responsable.apellidos }}</small
                >
                <vs-tooltip>
                  <i
                    :class="`fas fa-${
                      curso.privacidad === 1 ? 'earth-americas' : 'lock'
                    }`"
                  ></i>
                  <template #tooltip>{{
                    curso.privacidad === 1 ? "Curso público" : "Curso privado"
                  }}</template>
                </vs-tooltip>
              </div>
              <small class="d-flex align-items-center text-white-50 mt-2">
                <i class="fas fa-circle-check mr-1"></i> Última actualización:
                <span class="text-primary ml-1">
                  {{ curso.ultimaActualizacion | formatLastUpdateDate }}</span
                >
              </small>
              <!-- <div class="d-flex align-items-center">
                <b-form-rating
                  id="rating-inline"
                  variant="warning"
                  readonly
                  inline
                  show-value
                  no-border
                  value="3.8"
                ></b-form-rating>
                <small class="text-muted">(30)</small>
              </div> -->
            </template>
            <template #interactions>
              <vs-button   icon>
                <i class="far fa-clock mr-2"></i>
                <span class="span text-white"
                  >{{
                    curso.clases.reduce(
                      (totalHoras, clase) =>
                        totalHoras + parseInt(clase.duracion),
                      0
                    )
                  }}h</span
                >
              </vs-button>
              <vs-button class="btn-chat" dark icon>
                <i class="far fa-circle-play mr-2"></i>
                <span class="span">{{ curso.clases.length }}</span>
              </vs-button>
            </template>
          </vs-card>
        </vs-card-group>
        <b-popover
          v-for="curso in cursosPrivados"
          :target="`popover-${curso.id}-private`"
          triggers="hover focus"
          :key="curso.id"
        >
          <template #title>
            <h3 class="custom-text-truncate-title text-capitalize">
              {{ curso.titulo }}
            </h3>
          </template>
          <div class="d-flex justify-content-between my-2">
            <div class="d-flex align-items-center">
              <i
                class="fas fa-clock mr-1 text-warning"
                style="font-size: 14px"
              ></i>
              <b style="font-size: 14px"
                >{{
                  curso.clases.reduce(
                    (totalHoras, clase) =>
                      totalHoras + parseInt(clase.duracion),
                    0
                  )
                }}h</b
              >
            </div>

            <div>
              <i class="fas fa-play-circle mr-1" style="font-size: 14px"></i>
              <b class="text-primary" style="font-size: 14px"
                >{{ curso.clases.length
                }}{{ curso.clases.length > 1 ? " clases" : " clase" }}
              </b>
            </div>
          </div>
          <p
            class="custom-text-truncate-p text-white-50"
            style="font-size: 13px"
          >
            {{ curso.descripcion }}
          </p>
          <vs-button
             
            block
            :to="`/helex/Ayuda/Academia/InfoCurso/${curso.id}`"
          >
            <i class="fas fa-video mr-2"></i> Ir a curso
          </vs-button>
        </b-popover>
      </div>
      <!-- temas recomentados cursos -->
      <div v-if="listaCursosPorCategoria.length > 0">
        <h3 class="mt-4 mb-3 myBorder">Temas recomendados para ti</h3>
        <b-tabs
          content-class="mt-3"
          class="custom-tabs"
          pills
          v-model="tabIndex"
        >
          <b-tab
            v-for="(categoria, index) in listaCursosPorCategoria"
            :title="categoria.nombre"
            :key="categoria.nombre"
            :active="index === 0"
            :title-link-class="linkClass(index)"
          >
            <vs-card-group>
              <vs-card
                class="custom-card-academia"
                v-for="curso in categoria.cursos"
                :key="curso.id"
                :id="`popover-${curso.id}-${categoria.nombre}`"
              >
                <template #title>
                  <div class="custom-text-truncate-title">
                    <h3 class="text-capitalize">
                      {{ curso.titulo }}
                    </h3>
                  </div>
                </template>
                <template #img>
                  <img
                    style="min-height: 162.89px; max-height: 162.89px; border-radius: 10px;"
                    :src="curso.previewImagenUrl ?? `/img/image_cursos_alt.png`"
                    :alt="curso.titulo"
                  />
                </template>
                <template #text>
                  <div class="d-flex align-items-center text-white-50">
                    <small
                      class="custom-punto d-flex align-items-center text-capitalize"
                      >Por {{ curso.responsable.nombres }}
                      {{ curso.responsable.apellidos }}</small
                    >
                    <vs-tooltip>
                      <i
                        :class="`fas fa-${
                          curso.privacidad === 1 ? 'earth-americas' : 'lock'
                        }`"
                      ></i>
                      <template #tooltip>{{
                        curso.privacidad === 1
                          ? "Curso público"
                          : "Curso privado"
                      }}</template>
                    </vs-tooltip>
                  </div>
                  <small class="d-flex align-items-center text-white-50 mt-2">
                    <i class="fas fa-circle-check mr-1"></i> Última
                    actualización:
                    <span class="text-primary ml-1">
                      {{
                        curso.ultimaActualizacion | formatLastUpdateDate
                      }}</span
                    >
                  </small>
                  <!-- <div class="d-flex align-items-center">
                <b-form-rating
                  id="rating-inline"
                  variant="warning"
                  readonly
                  inline
                  show-value
                  no-border
                  value="3.8"
                ></b-form-rating>
                <small class="text-muted">(30)</small>
              </div> -->
                </template>
                <template #interactions>
                  <vs-button   icon>
                    <i class="far fa-clock mr-2"></i>
                    <span class="span text-white"
                      >{{
                        curso.clases.reduce(
                          (totalHoras, clase) =>
                            totalHoras + parseInt(clase.duracion),
                          0
                        )
                      }}h</span
                    >
                  </vs-button>
                  <vs-button class="btn-chat" dark icon>
                    <i class="far fa-circle-play mr-2"></i>
                    <span class="span">{{ curso.clases.length }}</span>
                  </vs-button>
                </template>
              </vs-card>
            </vs-card-group>
            <b-popover
              v-for="curso in categoria.cursos"
              :target="`popover-${curso.id}-${categoria.nombre}`"
              triggers="hover focus"
              :key="curso.id"
            >
              <template #title>
                <h3 class="custom-text-truncate-title text-capitalize">
                  {{ curso.titulo }}
                </h3>
              </template>
              <div class="d-flex justify-content-between my-2">
                <div class="d-flex align-items-center">
                  <i
                    class="fas fa-clock mr-1 text-warning"
                    style="font-size: 14px"
                  ></i>
                  <b style="font-size: 14px"
                    >{{
                      curso.clases.reduce(
                        (totalHoras, clase) =>
                          totalHoras + parseInt(clase.duracion),
                        0
                      )
                    }}h</b
                  >
                </div>

                <div>
                  <i
                    class="fas fa-play-circle mr-1"
                    style="font-size: 14px"
                  ></i>
                  <b class="text-primary" style="font-size: 14px"
                    >{{ curso.clases.length
                    }}{{ curso.clases.length > 1 ? " clases" : " clase" }}
                  </b>
                </div>
              </div>
              <p
                class="custom-text-truncate-p text-white-50"
                style="font-size: 13px"
              >
                {{ curso.descripcion }}
              </p>
              <vs-button
                 
                block
                :to="`/helex/Ayuda/Academia/InfoCurso/${curso.id}`"
              >
                <i class="fas fa-video mr-2"></i> Ir a curso
              </vs-button>
            </b-popover>
          </b-tab>
        </b-tabs>
      </div>

      <div v-if="listaCursosAcademia.length === 0" class="text-center">
        <p class="font-weight-bold font-italic h3">No hay cursos que mostrar</p>
      </div>
    </div>
  </b-card>
</template>

<script>
import moment_timezone from "moment-timezone";
export default {
  name: "Cursos",
  data() {
    return {
      persona: {},
      tabIndex: 0,
      cursoRandom: {
        id: null,
        titulo: "",
        descripcion: "",
        previewImagenUrl: null,
        privacidad: null,
        responsableId: null,
        responsable: {},
        certificadoFinalizacion: null,
        categoriaCurso: {},
        clases: [],
        categoriaCursoId: null,
        ultimaActualizacion: null,
      },
      cursosRecientes: [
        {
          id: null,
          titulo: "",
          descripcion: "",
          previewImagenUrl: null,
          privacidad: null,
          responsableId: null,
          responsable: {},
          certificadoFinalizacion: null,
          categoriaCurso: {},
          clases: [],
          categoriaCursoId: null,
          ultimaActualizacion: null,
        },
      ],
      cursosPublicos: [
        {
          id: null,
          titulo: "",
          descripcion: "",
          previewImagenUrl: null,
          privacidad: null,
          responsableId: null,
          responsable: {},
          certificadoFinalizacion: null,
          categoriaCurso: {},
          clases: [],
          categoriaCursoId: null,
          ultimaActualizacion: null,
        },
      ],
      cursosPrivados: [
        {
          id: null,
          titulo: "",
          descripcion: "",
          previewImagenUrl: null,
          privacidad: null,
          responsableId: null,
          responsable: {},
          certificadoFinalizacion: null,
          categoriaCurso: {},
          clases: [],
          categoriaCursoId: null,
          ultimaActualizacion: null,
        },
      ],
      listaCursosAcademia: [
        {
          id: null,
          titulo: "",
          descripcion: "",
          previewImagenUrl: null,
          privacidad: null,
          responsableId: null,
          responsable: {},
          certificadoFinalizacion: null,
          categoriaCurso: {},
          clases: [],
          categoriaCursoId: null,
          ultimaActualizacion: null,
        },
      ],
      listaCursosPorCategoria: [
        {
          nombre: "",
          cursos: [
            {
              id: null,
              titulo: "",
              descripcion: "",
              previewImagenUrl: null,
              privacidad: null,
              responsableId: null,
              responsable: {},
              certificadoFinalizacion: null,
              categoriaCurso: {},
              clases: [],
              categoriaCursoId: null,
              ultimaActualizacion: null,
            },
          ],
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listaCursosAcademia = [];
    this.cursoRandom = null;
    this.cursosRecientes = [];
    this.cursosPublicos = [];
    this.cursosPrivados = [];
    this.listaCursosPorCategoria = [];
    await this.getListCursosAcademia();
    if (this.listaCursosAcademia.length > 0) {
      this.generateCursoRandom();
      this.obtenerCursosRecientes();
      this.obtenerCursosPublicos();
      this.obtenerCursosPrivados();
      this.agruparCursosPorCategorias();
    }
    this.$isLoading(false);
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-danger", "text-light"];
      } else {
        return ["text-light"];
      }
    },
    agruparCursosPorCategorias() {
      // Verifica si listaCursosAcademia existe y es un array
      if (!Array.isArray(this.listaCursosAcademia)) {
        console.error("Error: listaCursosAcademia no es un array");
        return null;
      }

      // Utilizamos la función reduce para agrupar los cursos por categoría
      const cursosPorCategorias = this.listaCursosAcademia.reduce(
        (acumulador, curso) => {
          // Verifica si curso tiene la propiedad categoriaCurso definida
          if (!curso.categoriaCurso || !curso.categoriaCurso.descripcion) {
            console.error("Error: curso sin categoría válida", curso);
            return acumulador;
          }

          const { categoriaCurso, ...infoCurso } = curso;

          // Buscamos la categoría en el acumulador
          const categoriaExistente = acumulador.find(
            (cat) => cat.nombre === categoriaCurso.descripcion
          );

          if (categoriaExistente) {
            // Si la categoría ya existe, añadimos el curso al array existente
            categoriaExistente.cursos.push(infoCurso);
          } else {
            // Si la categoría no existe, la creamos y añadimos el curso
            acumulador.push({
              nombre: categoriaCurso.descripcion,
              cursos: [infoCurso],
            });
          }

          return acumulador;
        },
        []
      );

      console.log(
        "curso por categoria--------------------->",
        cursosPorCategorias
      );
      this.listaCursosPorCategoria = cursosPorCategorias;
    },
    obtenerCursosPublicos() {
      const cursosPublicos = this.listaCursosAcademia.filter(
        (curso) => curso.privacidad === 1
      );
      this.cursosPublicos = cursosPublicos;
    },
    obtenerCursosPrivados() {
      const cursosPrivados = this.listaCursosAcademia.filter(
        (curso) => curso.privacidad === 2
      );
      this.cursosPrivados = cursosPrivados;
    },
    obtenerCursosRecientes() {
      const ahora = new Date(); // Obtener la fecha actual
      const diasAtras = new Date(ahora);
      diasAtras.setDate(ahora.getDate() - 7); // 7 dias

      // Filtrar los cursos con ultimaActualizacion en los últimos 3 días
      const cursosRecientes = this.listaCursosAcademia.filter((curso) => {
        const ultimaActualizacion = new Date(curso.ultimaActualizacion);
        return ultimaActualizacion >= diasAtras;
      });

      // Ahora, cursosRecientes contiene solo los cursos actualizados en los últimos 3 días
      console.log(cursosRecientes);
      this.cursosRecientes = cursosRecientes;
    },
    generateCursoRandom() {
      let min = 0;
      let max = this.listaCursosAcademia.length;
      let numeroEnteroAleatorio =
        Math.floor(Math.random() * (max - min + 1)) + min;

      this.cursoRandom = this.listaCursosAcademia[numeroEnteroAleatorio];
    },
    async getListClasesCursoAcademia(cursoId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `ClasesCursosAcademia/All/${cursoId}`,
        });
        console.log("list clases cursoAcademia...", res);
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      } catch (error) {
        console.log("err", error);
        return [];
      }
    },
    async getListCursosAcademia() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `CursosAcademia/`,
        });
        console.log("list cursos academia...", res);

        if (res.success) {
          // Obtener todas las promesas de las listas de clases en paralelo
          const clasesPromises = res.data.map((curso) =>
            this.getListClasesCursoAcademia(curso.id)
          );

          // Esperar a que todas las promesas se resuelvan
          const clasesListas = await Promise.all(clasesPromises);

          // Asignar las listas de clases a cada curso
          this.listaCursosAcademia = res.data
            .map((curso, index) => ({
              ...curso,
              clases: clasesListas[index],
            }))
            .filter(
              (curso) =>
                (curso.privacidad === 1 ||
                  (curso.privacidad === 2 &&
                    curso.responsable.empresaId === this.persona.empresa.id)) &&
                curso.clases &&
                curso.clases.length > 0
            );

          console.log(
            "list cursos con clases academia...",
            this.listaCursosAcademia
          );
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  filters: {
    formatLastUpdateDate(fechaOriginal) {
      return moment_timezone
        .tz(fechaOriginal, "America/Bogota")
        .format("MMM D, YYYY");
    },
  },
};
</script>

<style scoped>

.custom-card {
  opacity: 1;
  transition: opacity 1s ease 0s;
  /* background-color: #0f213f; */
}
.custom-card:hover {
  opacity: 0.6;
}
.custom-text-truncate-p {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.custom-text-truncate-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.myBorder {
  border-left: 2px solid #f0002b;
  padding-left: 0.5rem;
}
</style>
